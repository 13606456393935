import React from 'react';
import { graphql } from 'gatsby';
import { ItemsListIndexPage, PageWithSubNav, Paragraph, Section } from 'docComponents';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav pageType="design" title="Content Elements" subnav="words">
      <Section>
        <Paragraph>Consider these buddies the building blocks of all product copy.</Paragraph>

        <Paragraph>
          Content elements may seem simple in theory, but they carry a ton of weight when combined to create content
          patterns, like schedule entries and feed cards.
        </Paragraph>
        <Paragraph>
          It’s important we pay close attention to capitalization, punctuation and any efforts to abbreviate in these
          elements. The smallest tweak to one instance can really throw our progress in creating a cohesive experience.
        </Paragraph>
      </Section>

      <Section>
        <ItemsListIndexPage data={data} tierTwo="content-elements" listType="word" listLayout="2" />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query voicetoneIndexQuery($tierOne: String = "Words") {
    ...indexList
  }
`;
